import React,{ useState } from 'react';

function ThemeToggle(){

    const [darkMode, setDarkMode] = useState(false);

    const handleToggle = () => {

        setDarkMode(!darkMode);
    

    document.body.classList.toggle('dark-theme', !darkMode);
};
return(
    <div>
        <label>
            <input type = "checkbox" onChange = {handleToggle}/>
            Toggle Dark Mode
        </label>
    </div>
);





}

export default ThemeToggle;