import React, {useState, useEffect} from 'react';

function Counter() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        document.title = `you clicked ${count} times`;
    }, [count]);

    return (
        <div>
            <p>you clicked {count} times</p>
            <button onClick = {() => setCount(count + 1)}>Click me</button>
        </div>
    );
}

export default Counter;