import React, {useRef} from 'react';

function FuncUseRef(){
    const boxRef = useRef(null);

    const changeBackground = () => {


        const box = boxRef.current;

        if(box.style.backgroundColor === 'lightgray'){

            box.style.backgroundColor='lightblue';



        }

        else{
            box.style.backgroundColor='lightgray';
        }





        // if(box.style.display === 'block'){
        //     box.style.display = 'none';
        // }
        // else{
        //     box.style.display = 'block';
        // }
    };

    return (

        <div style={ {padding:'20px'}}>
            <div ref={boxRef} style={{
                width: '200px',
                heiight: '200px',
                backgroundColor: 'lightgray',
                marginBottom: '20px',
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid black'
            }}>
                click the button!
            </div>

            <button onClick = {changeBackground}>
                Change Background
            </button>
            



        </div>
    )
}

export default  FuncUseRef;