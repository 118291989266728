import React,{ useState } from 'react';
import './App.css';
import Counter from './Counter';
import ThemedButton from './ThemedButton';


import ThemeToggle from './ThemeToggle';

import UseRef from './FuncUseRef.js';


// import Reducer from './Reducer.js';
import TextInputWithFocusButton from './TextInputWithFocusButton';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Home from './Home';
import About from './About';
import './App.css'; 
function App(){
  
  
  // const [message, setMessage] = useState('Click on any Box!');

  

  const [mess, setMessage] = useState('click on any one box');

  function selectColor(box)  {


if(box == 'box1') {
  setMessage("congratulation!");
}

if(box == 'box2') {
  setMessage("better luck nect time!");
}


  };



  return (
    <>
    


    <div>
      <p>{mess}</p>
      <div className='box-main'>
        <div onClick = {() => selectColor('box1')} className = 'box1'>Box 1</div>
        <div onClick = {() => selectColor('box2')} className = 'box2'>Box 2</div>

      </div>
    </div>

    <Counter/>
    <ThemedButton/>

    <TextInputWithFocusButton/>

    {/* <Reducer/> */}

    <UseRef/>

    <div className="App">
            <h1>Welcome to theme toggler</h1>
            <ThemeToggle/>
        </div>
    
    </>




  );


  
    
  







    
  //awdeshkumar 
  // <Router>
  //     <div>
  //       <nav>
  //         <div className="navbar">
  //           <div className="nav-link-container">
  //             <div className="nav-search">Search box</div>
  //             <div className="nav-logo">Logo</div>
  //             <div className="nav-icon">
  //               <div className="nav-wish">wish</div>
  //               <div className="nav-bell">Bell</div>
  //               <div className="nav-login">Login</div>
  //             </div>
  //           </div>

  //         </div>
  //         <div className="nav-links">
  //         <div className="nav-main-link">
  //         <Link to="/">Home</Link>
  //         <Link to="/about">About Us</Link>
  //         </div>
  //         <div className="nav-cart">
  //           <div>Cart</div>
  //           <div>Price</div>
  //           <div>Item</div>
  //         </div>

  //         </div>

          
  //       </nav>
  //   <Routes>
  //     <Route path="/" element={<Home/>}/>
  //     <Route path="/about" element={<About/>}/>
  //   </Routes>

  //     </div>
  // </Router>
    
    

}
export default App;



// //import logo from './logo.svg';
// import './App.css';
// import Hello from './Hello';
// import HomePage from './HomePage';
// import Welcome from './Welcome';
// import ReactClass from './ReactClass';
// import ParentComponent from './ParentComponent';
// function App() {
//   return (

//   <>
//   <ParentComponent/>

//   <HomePage/>
//   <Hello />
//   <Welcome name='john'/>
//   {/* done,ab iss mai props kese bheju like Anshul */}
//   <ReactClass name='krishna'/>
//   </>
//   );
// }

// export default App;


// {/* Search Bar */}
// <div class="search-bar">
// <div class="container">
//   <div class="top-menu">
//     {/* top menu sidebar button */}
//     <button>
//       <svg>

//       </svg>
      
//     </button>
//     {/* search field */}
//     <div class="search-field">
//       <form action="https://awdheshkumar.in/search">
//       <div>
//         <div>
//           <button>...</button>
//         </div>
//         <div class="search-input-box">
//           <input type="text" placeholder="I am shopping for..."></input>
//           <svg>

//           </svg>
//         </div>
//       </div>

//       </form>
//       <div>

//       </div>
//       {/* header logo */}
//       <div class="header-logo">
//         <Link to="https://awdheshkumar.in/"><img src="https://cc-prod.scene7.com/is/image/CCProdAuthor/mascot-logo-design_P1_900x420?$pjpeg$&jpegSize=200&wid=900"></img>
//         </Link>

//       </div>
//       {/*  */}

                      
//     </div>
//   </div>
// </div>

// </div>
// <ul>
// <li>
// <Link to="/">Home</Link>
// </li>
// <li>
// <Link to='/about'>About</Link>
// </li>
// </ul>